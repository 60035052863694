<template>
    <div>
        <v-app-bar app dense
            :color="$route.hasOwnProperty('meta') ? ($route.meta.hasOwnProperty('theme') ? $route.meta.theme : appBarColor) : appBarColor">
            <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
            <v-app-bar-title class="subtitle-1 white--text pl-0">{{ $route.name.toUpperCase() }}</v-app-bar-title>
        </v-app-bar>

        <v-navigation-drawer app v-model="drawer">
            <v-list-item two-line>
                <v-list-item-icon>
                    <v-img src="../assets/jaen_logo.png" max-width="40" max-height="40"></v-img>
                </v-list-item-icon>
                <v-list-item-content class="pt-0">
                    <v-list-item-title class="font-weight-bold">Jaen Health Center</v-list-item-title>
                    <v-list-item-subtitle>Queue Management System</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list>
                <div v-for="(item, index) in navButtons" :key="index" width="100%">
                    <v-list-item v-if="!item.children && item.users.includes(currentUser)" :to="{ name: item.path }">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name" class="text-overline"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-group no-action :prepend-icon="item.icon" v-else color="none">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name" class="text-overline"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-for="(child, index) in item.children" :key="index" :to="{ name: child.path }" v-if="child.users.includes(currentUser)">
                            <v-list-item-content>
                                <v-list-item-title v-text="child.name" class="text-overline"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </div>
            </v-list>

            <template v-slot:append>
                <v-divider></v-divider>
                <div class="pa-4">
                    <v-btn block text class="red--text" @click="logOut()">
                        <v-icon left>mdi-logout</v-icon>
                        Log out
                    </v-btn>
                </div>
            </template>

        </v-navigation-drawer>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Navigation',

    data: () => ({
        drawer: true,
        appBarColor: "#3F51B5",
        currentUser: localStorage.getItem('user'),
        navButtons: [
            // {
            //     name: "Dashboard",
            //     icon: "mdi-view-dashboard",
            //     path: 'Dashboard',
            //     children: null,
            //     users: []
            // },
            {
                name: "Ticket",
                icon: "mdi-ticket-account",
                path: null,
                children: [
                    {
                        name: "Doctor 1",
                        path: "Ticket (Doctor One)",
                        users: ['QMS ADMIN', 'SUPER ADMIN', 'DOCTOR 1']
                    },
                    {
                        name: "Doctor 2",
                        path: "Ticket (Doctor Two)",
                        users: ['QMS ADMIN', 'SUPER ADMIN', 'DOCTOR 2']
                    },
                    {
                        name: "Dental",
                        path: "Ticket (Dental)",
                        users: ['QMS ADMIN', 'SUPER ADMIN', 'DENTAL']
                    },
                    {
                        name: "Others",
                        path: "Ticket (Other)",
                        users: ['QMS ADMIN', 'SUPER ADMIN', 'OTHER']
                    }
                ]
            },
            {
                name: "Display Board",
                icon: "mdi-cast-connected",
                path: 'Display Board',
                children: null,
                users: ['QMS ADMIN', 'SUPER ADMIN', 'DOCTOR 1', 'DOCTOR 2', 'DENTAL', 'OTHER']
            }
        ]
    }),
    computed: {
        ...mapGetters(['getBaseURL'])
    },
    methods: {
        ...mapActions(['logoutUser']),

        logOut: function() {
            // this.logoutUser({url: localStorage.getItem('base_URL')})
            this.logoutUser({url: this.getBaseURL.url})
        }
    }
}
</script>

<style scoped></style>
  