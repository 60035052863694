<template>
  <v-app>
    <div v-if="$route.name === 'Login' || $route.name === 'Register' ? false : true">
      <Navigation></Navigation>
    </div>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navigation from './components/Navigation.vue'

export default {
  name: 'App',
  components: {
    Navigation
  },

  data: () => ({
    // baseURL: null
  }),
  methods: {
    ...mapActions(['getAllQueueData', 'getAllNowServing', 'setBaseURL', 'updateBaseURL']),
  },
  computed: {
    ...mapGetters(['getBaseURL']),
    baseURL() {
      return this.getBaseURL.url
    }

  },
  mounted() {
    this.getAllQueueData()
    this.getAllNowServing()
    console.log('app')

    //Gets the URL from firebase and saves it to store
    //Tests the URL if working by making a POST request to /api/test endpoint
    //If successful, updates the URL with the used base URL
    //If not, removes the token and user from localStorage to ensure there's no current user

    if (localStorage.getItem('user') === null || localStorage.getItem('user') === undefined) {
      this.setBaseURL().then(() => {
        fetch(this.baseURL + '/api/test', { method: 'POST' })
          .then(response => {
            if (response.status >= 200 && response.status < 300) {

              this.updateBaseURL({ url: this.baseURL, isValid: true })

            } else {
              if (localStorage.getItem('token')) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
              }
              if (this.$route.name !== 'Login') {
                this.$router.push({ name: 'Login' })
              }
              this.updateBaseURL({ url: this.baseURL, isValid: false })
            }
          })
          .catch(error => {
            this.updateBaseURL({ url: this.baseURL, isValid: false })
            if (localStorage.getItem('token')) {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
            }
            if (this.$route.name !== 'Login') {
              this.$router.push({ name: 'Login' })
            }
            console.log(error)
          })
      })
    }
  }
};
</script>
