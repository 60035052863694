<template>
    <v-card outlined flat>
        <v-card-title>
            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" single-line></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :search="searchItem">
            <template v-slot:item="items">
                <tr>
                    <td>{{ items.item.name.toUpperCase() }}</td>
                    <td>{{ items.item.age.toUpperCase() }}</td>
                    <td>{{ items.item.gender.toUpperCase() }}</td>
                    <td>{{ items.item.appointmentType.toUpperCase() }}</td>
                    <td>{{ items.item.code.toUpperCase() }}</td>
                    <td>{{ items.item.priorityType.toUpperCase() }}</td>
                    <td>
                        <v-icon color="red" @click="deleteTicket(items.item)">mdi-delete</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-card>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';


export default {
    name: 'DataTable',
    props: [
        'headers',
        'items',
    ],
    data: () => ({
        searchItem: null
    }),
    computed: {
        ...mapGetters(['getAllQueues']),

        currentRouteQueue() {
            switch (this.$route.meta.name) {
                case 'Doctor 1':
                    return this.getAllQueues.doctorOne

                case 'Doctor 2':
                    return this.getAllQueues.doctorTwo

                case 'Dental':
                    return this.getAllQueues.dental

                case 'Other':
                    return this.getAllQueues.other

                default:
                    break;
            }
        }
    },
    methods: {
        ...mapActions(['updateQueue']),

        deleteTicket: function (client) {
            console.log(this.currentRouteQueue)
            console.log(client)
            this.updateQueue({ key: this.$route.meta.firebaseKey, currentClient: client, currentQueue: this.currentRouteQueue })
        }
    }
}
</script>
  