<template>
    <v-container>
        <v-card flat class="mx-auto" max-width="300">
            <v-card-text class="d-flex flex-column align-center black--text">
                <v-img max-width="150" max-height="150" src="../../assets/jaen_logo.png"></v-img>
                <v-card-title>Jaen Health Center</v-card-title>
                <v-card-subtitle>Queue Management System</v-card-subtitle>
            </v-card-text>
            <div class="d-flex flex-column" v-if="getBaseURL.isValid">
                <v-card-title class="mx-auto">Sign in</v-card-title>
                <v-text-field dense outlined label="USERNAME" v-model="credentials.username"></v-text-field>
                <v-text-field dense outlined label="PASSWORD" v-model="credentials.password"></v-text-field>
                <v-btn block :loading="loading" class="mb-2" id="signin-button" @click="login()">SIGN IN</v-btn>
            </div>
            <div v-if="!getBaseURL.isValid">
                <v-text-field dense outlined label="BASE URL" v-model="baseURL"
                    :error-messages="errorMessage"></v-text-field>
                <v-btn :loading="loading || firebaseLoading" block class="mb-2" id="submit-button" @click="createConnection()">SUBMIT</v-btn>
                <v-card-text class="text-center">{{ getBaseURL.message }}</v-card-text>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Login',

    data: () => ({
        credentials: {
            username: null,
            password: null
        },
        baseURL: null,
        errorMessage: null,
        loading: false
    }),
    computed: {
        ...mapGetters(['getBaseURL', 'getLoading']),

        firebaseLoading() {
            if(this.getBaseURL.message === 'Connecting to Firebase, please wait.'){
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions(['updateBaseURL', 'loginUser']),

        createConnection: function () {
            this.errorMessage = null
            this.loading = true
            fetch(this.baseURL + '/api/test', { method: 'POST' }) //concatenate the endpoint here
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        this.loading = false
                        this.updateBaseURL({ url: this.baseURL, isValid: true })
                    } else {
                        this.errorMessage = 'Invalid URL'
                        this.loading = false
                        this.updateBaseURL({ url: 'http://localhost', isValid: false })
                    }
                })
                .catch(error => {
                    this.loading = false
                    this.errorMessage = "Can't connect to the API. Check the URL, XAMPP, or internet connection."
                    console.log(error)
                })
        },

        login: function () {
            // this.loginUser({ credentials: this.credentials, url: this.getBaseURL.url })
            // Make a POST request to the login API endpoint with the provided payload
            this.loading = true
            fetch(this.getBaseURL.url + '/api/login', {
                method: 'POST',
                body: JSON.stringify({
                    username: this.credentials.username,
                    password: this.credentials.password
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(async (response) => {
                    const data = await response.json()

                    // Check if the message in the response indicates successful login
                    if (response.status >= 200 && response.status < 300) {

                        // Store the token and user name in the browser's local storage
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('user', data.user.name)

                        // Redirect the user to the 'Dashboard' route
                        this.$router.push({ name: 'Display Board' })
                    } else {
                        // Display an alert message indicating the wrong username or password
                        this.loading = false
                        alert(data.message)
                    }
                })
                .catch(error => {
                    // Display an alert message
                    this.loading = false
                    alert('Something went wrong. Try refreshing the page')
                    console.log(error.message)
                })
        }
    }
}
</script>

<style scoped>
#signin-button,
#submit-button {
    color: white;
    background-color: #2F58CD;
}
</style>
